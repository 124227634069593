import EditDenimProduct from "./EditDenimProduct";
import HorizontalProducts from "./HorizontalProducts";
import ProductHeader from "./ProductHeader";
import WashTabs from "./WashTabs";
import { v4 as uuidv4 } from "uuid";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

const DenimProduct = ({
  oneProduct,
  editMode,
  setEditMode,
  totalLook,
  similarProducts,
  isLoadingTotalLook,
  isLoadingSimilar,
}: any) => {
  console.log(similarProducts);
  const { user, isAdmin } = useSelector((state: any) => state.auth);

  return !editMode ? (
    <>
      <ProductHeader
        oneProduct={oneProduct}
        setEditMode={setEditMode}
        editMode={editMode}
      />
      <div className="details">
        {oneProduct && oneProduct.fabrics.length > 0 && (
          <h3 className="wash_title mb-2">Fabric</h3>
        )}
        {oneProduct &&
          oneProduct.fabrics.length > 0 &&
          oneProduct.fabrics.map((el: any, index: any) => {
            return (
              <div className="details_fabric">
                <div>
                  <h5 className="fabric_title">
                    {el?.fabric_mill?.name} {el?.name} - {el?.color}{" "}
                  </h5>
                  {oneProduct && el.description && (
                    <p className="paragraphe fabric_text ">{el.description}</p>
                  )}
                </div>
                <table
                  className="fabric-table-details"
                  style={{ width: "100%" }}
                >
                  <tbody>
                    {el && (
                      <>
                        <tr>
                          <td style={{ width: "33%" }}>
                            <span style={{ fontWeight: 500 }}>
                              {" "}
                              Fabric Type :{" "}
                            </span>{" "}
                            {el?.fabric_types && (
                              <span>{el?.fabric_types?.name}</span>
                            )}
                          </td>
                          <td style={{ width: "33%" }}>
                            {" "}
                            <span style={{ fontWeight: 500 }}>
                              {" "}
                              Composition :{" "}
                            </span>
                            {el?.composition_value && (
                              <span>{el?.composition_value}</span>
                            )}
                          </td>
                          <td style={{ width: "33%" }}>
                            {" "}
                            <span style={{ fontWeight: 500 }}>
                              {" "}
                              Elasticity :{" "}
                            </span>
                            {el?.elasticity_value && (
                              <span>{el?.elasticity_value}</span>
                            )}
                          </td>
                        </tr>

                        <tr>
                          <td
                            style={{
                              width:
                                user.is_client_clients !== 1 ? "33%" : "33%",
                              borderRight:
                                user.is_client_clients !== 1 ? "" : "none",
                            }}
                          >
                            <span style={{ fontWeight: 500 }}> Weight : </span>
                            {el?.weight_value && (
                              <span>{el?.weight_value}</span>
                            )}
                          </td>
                          {user.is_client_clients !== 1 && (
                            <td style={{ width: "33%" }}>
                              <>
                                <span style={{ fontWeight: 500 }}>
                                  {" "}
                                  Price :{" "}
                                </span>{" "}
                                {el?.price && (
                                  <span>
                                    {Math.fround(el?.price).toFixed(2)} €
                                  </span>
                                )}
                              </>
                            </td>
                          )}
                          {user.is_client_clients !== 1 && (
                            <td style={{ width: "33%" }}>
                              <>
                                <span style={{ fontWeight: 500 }}>
                                  {" "}
                                  Washed price(/150) :{" "}
                                </span>
                                {el?.price_150 && (
                                  <span>
                                    {Math.fround(el?.price_150).toFixed(2)} €
                                  </span>
                                )}
                              </>
                            </td>
                          )}
                        </tr>
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            );
          })}

        {/*    <div className="details_fabric">
          <div>
            <h3 className="fabric_title">Fabric </h3>
            {oneProduct && oneProduct.fabric_details && (
              <p className="paragraphe fabric_text ">
                {oneProduct.fabric_details}
              </p>
            )}
          </div>

          <table className="fabric-table-details" style={{ width: "100%" }}>
            <tbody>
              {oneProduct && oneProduct?.fabrics?.length > 0 && (
                <tr>
                  <th>Fabric Mill</th>
                  {oneProduct && (
                    <td>
                      {oneProduct?.fabrics?.length > 0 &&
                        oneProduct.fabrics.map((el: any, key: number) =>
                          key === oneProduct?.fabrics?.length - 1 &&
                          el?.fabric_mill?.name != null
                            ? `${el?.fabric_mill?.name}`
                            : `_ •`
                        )}
                    </td>
                  )}
                </tr>
              )}

              {oneProduct && oneProduct?.fabric_chars?.length > 0 && (
                <tr>
                  <th>Fabric Name</th>
                  {oneProduct && (
                    <td>
                      {oneProduct?.fabric_chars?.length > 0 &&
                        oneProduct.fabric_chars.map((el: any, key: number) =>
                          key === oneProduct?.fabric_chars?.length - 1 &&
                          el.fabric_name != null
                            ? `${el.fabric_name}`
                            : `- •`
                        )}
                    </td>
                  )}
                </tr>
              )}
              {oneProduct && oneProduct.colors.length > 0 && (
                <tr>
                  <>
                    <th>Fabric Colour</th>
                    <td>
                      {oneProduct?.colors.map((el: any, key: number) =>
                        key === oneProduct.colors.length - 1
                          ? `${el.name}`
                          : `${el.name} • `
                      )}
                    </td>
                  </>
                </tr>
              )}
              {oneProduct && oneProduct?.fabric_chars?.length > 0 && (
                <tr>
                  <th>Fabric Type</th>
                  {oneProduct && (
                    <td>
                      {oneProduct?.fabric_chars?.length > 0 &&
                        oneProduct.fabric_chars.map((el: any, key: number) =>
                          key === oneProduct?.fabric_chars?.length - 1
                            ? `${el.name}`
                            : `${el.name} • `
                        )}
                    </td>
                  )}
                </tr>
              )}

              {oneProduct && oneProduct.fabric_composition && (
                <tr>
                  <th>Composition</th>
                  {oneProduct && (
                    <td className="pr-5">{oneProduct.fabric_composition}</td>
                  )}
                </tr>
              )}
              <tr>
                {oneProduct && oneProduct.elasticity !== null && (
                  <>
                    <th>Elasticity</th>
                    <td>
                      {oneProduct.elasticity === 0
                        ? `Rigid`
                        : Math.floor(oneProduct.elasticity * 100) > 0 &&
                          Math.floor(oneProduct.elasticity * 100) <= 25
                        ? `${Math.floor(oneProduct.elasticity * 100)}% Comfort`
                        : Math.floor(oneProduct.elasticity * 100) >= 26 &&
                          Math.floor(oneProduct.elasticity * 100) <= 45
                        ? `${Math.floor(oneProduct.elasticity * 100)}% Stretch`
                        : Math.floor(oneProduct.elasticity * 100) >= 46 &&
                          Math.floor(oneProduct.elasticity * 100) <= 75
                        ? `${Math.floor(
                            oneProduct.elasticity * 100
                          )}% Power stretch`
                        : Math.floor(oneProduct.elasticity * 100) >= 76 &&
                          Math.floor(oneProduct.elasticity * 100) <= 100 &&
                          `${Math.floor(
                            oneProduct.elasticity * 100
                          )}% Hyper stretch`}
                    </td>
                  </>
                )}
              </tr>

              {oneProduct &&
                oneProduct?.fabric_chars?.length > 0 &&
                oneProduct.fabric_chars[0].weight && (
                  <tr>
                    <th>Weight</th>
                    {oneProduct.fabric_type === "denim" &&
                      oneProduct.fabric_chars.length > 0 && (
                        <td>
                          {oneProduct.fabric_chars.length > 1
                            ? oneProduct.fabric_chars.map(
                                (el: any, key: number) =>
                                  key === oneProduct.fabric_chars.length - 1
                                    ? `${el.name} ${el.weight}oz`
                                    : `${el.name} ${el.weight}oz • `
                              )
                            : `${oneProduct.fabric_chars[0].weight} oz`}
                        </td>
                      )}
                  </tr>
                )}

              {oneProduct && oneProduct.fabric_price !== 0 && (
                <tr>
                  <th>Price</th>
                  {oneProduct?.fabric_chars?.length > 0 && (
                    <td>
                      {oneProduct?.fabric_chars?.length > 1
                        ? oneProduct?.fabric_chars?.map(
                            (el: any, key: number) =>
                              key === oneProduct.fabric_chars.length - 1
                                ? `${el.name} ${Math.fround(el.price).toFixed(
                                    2
                                  )}€`
                                : `${el.name} ${Math.fround(el.price).toFixed(
                                    2
                                  )}€ • `
                          )
                        : `${Math.fround(
                            oneProduct.fabric_chars[0].price
                          ).toFixed(2)}€`}
                    </td>
                  )}
                </tr>
              )}

              {oneProduct && oneProduct.fabric_price !== 0 && (
                <tr>
                  <th>Washed price (/150)</th>
                  {oneProduct?.fabric_chars?.length > 0 && (
                    <td>
                      {oneProduct?.fabric_chars?.length > 1
                        ? oneProduct?.fabric_chars?.map(
                            (el: any, key: number) =>
                              key === oneProduct.fabric_chars.length - 1
                                ? `${el.name} ${Math.fround(
                                    el.price_150
                                  ).toFixed(2)}€`
                                : `${el.name} ${Math.fround(
                                    el.price_150
                                  ).toFixed(2)}€ • `
                          )
                        : `${Math.fround(
                            oneProduct.fabric_chars[0].price_150
                          ).toFixed(2)}€`}
                    </td>
                  )}
                </tr>
              )}
            </tbody>
          </table>
        </div> */}
        {/* {tech && oneProduct.technic_id && (
          <div className="details_wash my-4">
            <h3 className="wash_title mb-2">Technique</h3>
            <table style={{ maxWidth: 590 }}>
              <tbody>
                <tr>
                  <th>Technique 1</th>
                  <td>{tech.name}</td>
                  <td>{tech.price}€</td>
                </tr>
              </tbody>
            </table>
          </div>
        )} */}

        {/* DO NOT DELETE !!!! */}

        {oneProduct &&
          oneProduct?.techniques &&
          oneProduct?.techniques?.length !== 0 && (
            <div className="details_wash">
              <h3 className="wash_title mb-2">Technique</h3>
              <table style={{ width: "100%" }}>
                <tbody>
                  {oneProduct.techniques.map((el: any, i: number) => (
                    <tr key={i}>
                      {/* <th>Technique Type {i + 1}</th>
                      <td>{el.name}</td>
                      <td>PRICE: {`${Math.fround(el.price).toFixed(2)}€`}</td> */}
                      <td style={{ width: "66%" }}>
                        {" "}
                        <span style={{ fontWeight: 500 }}>
                          Technique Type :{" "}
                        </span>{" "}
                        {el.name}
                      </td>

                      <td style={{ width: "33%" }}>
                        {user.is_client_clients !== 1 && (
                          <>
                            <span style={{ fontWeight: 500 }}>
                              {" "}
                              Price (/product) :{" "}
                            </span>
                            {`${Math.fround(el.price).toFixed(2)}€`}
                          </>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

        {oneProduct &&
          oneProduct?.accessories &&
          oneProduct?.accessories?.length !== 0 && (
            <div className="details_wash">
              <h3 className="wash_title mb-2">Accessories </h3>
              <table style={{ width: "100%" }}>
                <tbody>
                  {oneProduct.accessories.map((el: any, i: number) => (
                    <>
                      {" "}
                      <tr key={i}>
                        {/*   <th>Accessory Type {i + 1}</th>
                        <td>{el.name}</td>
                        <td>PRICE: {`${Math.fround(el.price).toFixed(2)}€`}</td>
                        <td>Unit: {el.unit ? "Per " + el.unit : "-"} </td> */}
                        <td style={{ width: "66%" }}>
                          {" "}
                          <span style={{ fontWeight: 500 }}>
                            Accessory Type :{" "}
                          </span>{" "}
                          {el.name}
                        </td>
                        <td style={{ width: "33%" }}>
                          {user.is_client_clients !== 1 && (
                            <>
                              Price (/product):{" "}
                              {`${Math.fround(el.price).toFixed(2)}€`}
                            </>
                          )}
                        </td>
                      </tr>
                    </>
                  ))}
                </tbody>
              </table>
            </div>
          )}

        {oneProduct &&
          oneProduct?.washes &&
          oneProduct?.washes?.length !== 0 && (
            <div className="details_wash">
              <h3 className="wash_title mb-2">Wash </h3>
              <table style={{ width: "100%" }}>
                <tbody>
                  {oneProduct.washes.map((el: any, i: number) => (
                    <>
                      {" "}
                      <tr key={i}>
                        {/* <td style={{ width: "33%" }}>
                          {" "}
                          <span style={{ fontWeight: "bold" }}>
                            Wash/dye type :{" "}
                          </span>{" "}
                          {el?.name}
                        </td> */}
                        <td style={{ width: "66%" }}>
                          <span style={{ fontWeight: 500 }}> Eim Score : </span>
                          <span className="mb-0">{el?.eim_score}</span>
                        </td>
                        {user.is_client_clients !== 1 && (
                          <td style={{ width: "33%" }}>
                            <>
                              <span style={{ fontWeight: 500 }}> Price : </span>
                              <>{Math.fround(el?.wash_price).toFixed(2)}€</>
                            </>
                          </td>
                        )}
                      </tr>
                    </>
                  ))}
                </tbody>
              </table>
            </div>
          )}

        {oneProduct && !oneProduct?.with_options ? (
          <>
            {/*   <div
              className="details_wash"
              style={
                oneProduct &&
                (oneProduct?.eim_Score ||
                  oneProduct?.washes?.length ||
                  oneProduct?.colors?.length > 0)
                  ? {}
                  : { display: "none" }
              }
            >
              <h3 className="wash_title mb-2">Wash</h3>
              {oneProduct && (
                <div className="wash-details-part d-flex flex-column align-items-stretch">
                  <div className="d-flex">
                    <div className="wash-cell-title border p-3">
                      <h6 className="mb-0">Wash/dye type</h6>
                    </div>
                    <div className="details-part d-flex">
                      {oneProduct?.washes?.map((wash: any, index: number) => (
                        <div
                          key={index}
                          className="wash-cell-value p-3 pr-0 border-top border-right border-bottom"
                          style={{
                            minWidth: `calc(100% / ${oneProduct?.washes?.length})`,
                          }}
                        >
                          <p className="mb-0">{wash?.name}</p>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="d-flex">
                    <div className="wash-cell-title border-left border-right border-bottom p-3">
                      <h6 className="mb-0">EIM Score</h6>
                    </div>
                    <div className="details-part d-flex">
                      {oneProduct?.washes?.map((wash: any, index: number) => (
                        <div
                          key={index}
                          className="wash-cell-value p-3 pr-0 border-right border-bottom"
                          style={{
                            minWidth: `calc(100% / ${oneProduct?.washes?.length})`,
                          }}
                        >
                          <p className="mb-0">{wash?.eim_score}</p>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="wash-cell-title border-left border-right border-bottom p-3">
                      <h6 className="mb-0">Price</h6>
                    </div>
                    <div className="details-part d-flex">
                      {oneProduct?.washes?.map((wash: any, index: number) => (
                        <div
                          key={index}
                          className="wash-cell-value p-3 pr-0 border-right border-bottom"
                          style={{
                            minWidth: `calc(100% / ${oneProduct.washes.length})`,
                          }}
                        >
                          <p className="mb-0">
                            {wash.wash_price ? (
                              <>{Math.fround(wash.wash_price).toFixed(2)}€</>
                            ) : (
                              <>-</>
                            )}
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </div> */}

            <hr />
            {user.is_client_clients !== 1 && (
              <div
                className="total_price_container"
                style={{ marginBottom: "25px !important" }}
              >
                {oneProduct?.special_price ? <>
                  <h3 className="total_special_title" >Special Price</h3>

                  {oneProduct && (
                  <p className="total_special">{`${Math.fround(
                    oneProduct?.total_price
                  ).toFixed(2)}€`}</p>
                )}
                </> :<>
                <h3 className="total_price_title">Total Price</h3>
                {oneProduct && (
                  <p className="total_price">{`${Math.fround(
                    oneProduct?.total_price
                  ).toFixed(2)}€`}</p>
                )}
                </>}
               
              </div>
            )}

            {user.is_client_clients !== 1 && (
              <>
                {oneProduct?.total_price_w_embellishment !=
                  oneProduct?.total_price && (
                  <>
                    {oneProduct?.total_price -
                      oneProduct?.total_price_w_embellishment !=
                      oneProduct?.total_price && (
                      <div
                        className="total_price_container"
                        style={{ marginBottom: "25px !important" }}
                      >
                        <h3 className="total_price_title">
                          Total Price without embellishment
                        </h3>
                        {oneProduct && (
                          <p className="total_price">{`${Math.fround(
                            oneProduct?.total_price_w_embellishment
                          ).toFixed(2)}€`}</p>
                        )}
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </>
        ) : (
          <>
            <h3 className="wash_title mb-2">Wash</h3>
            <WashTabs
              editMode={editMode}
              washes={oneProduct && oneProduct?.washes}
              oneProduct={oneProduct}
            />
          </>
        )}

        {oneProduct && oneProduct?.tags && oneProduct?.tags?.length > 0 ? (
          <div className="tags_container">
            <h3 className="wash_title">TAGS</h3>
            <div className="tag_list">
              {oneProduct &&
                oneProduct?.tags?.map((el: any) => (
                  <Link
                    to={`/products?q=${el?.id}&tag=${el?.name}`}
                    key={el?.id}
                  >
                    <p className="tag">{el?.name}</p>
                  </Link>
                ))}
            </div>
          </div>
        ) : null}
      </div>
      {oneProduct && (
        <>
          <HorizontalProducts
            data={totalLook}
            isLoading={isLoadingTotalLook}
            type={"total-look"}
            fabric={"denim"}
            key={uuidv4()}
          />
          <HorizontalProducts
            data={similarProducts}
            //  data={similarProduct}
            isLoading={isLoadingSimilar}
            type={"similar"}
            fabric={"denim"}
            key={uuidv4()}
          />
        </>
      )}
    </>
  ) : (
    <EditDenimProduct
      oneProduct={oneProduct}
      setEditMode={setEditMode}
      editMode={editMode}
    />
  );
};

export default DenimProduct;
