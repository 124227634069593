import { useState, useEffect, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addFilter,
  filter,
  deleteFilter,
  filterAndKeepAll,
  getCollectionProductsNextPage,
  clearAllFilters,
} from "../actions/products-actions/actions";
/*
//@ts-ignore
import { Navigation } from 'swiper/modules';
*/

import { Drawer, Skeleton, Card, Select, Spin, Button } from "antd";
import { useParams, useLocation } from "react-router-dom";
import "../components/CollectionProducts/CollectionProducts.scss";
import { Link, NavLink } from "react-router-dom";
import placeholder_jeans from "../img/placeholder_jeans.svg";

import backArrow from "../img/backArrow.png";
import { history } from "../index";
import { Collapse } from "antd";
import { Pagination, Navigation, Keyboard } from "swiper";
/*
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
*/
import { Swiper, SwiperSlide } from "swiper/react";

import { LoadingOutlined, UserOutlined } from "@ant-design/icons";

import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";
import onboarding_img from "../img/onboarding_img.png";
import axiosInstance from "../config/axios-instance";
import { env } from "process";
import { borderRightStyle } from "html2canvas/dist/types/css/property-descriptors/border-style";
import { useQuery } from "react-query";
import { Helmet } from "react-helmet";
import MySlider from "../components/MySlider/MySlider";
import { v4 as uuidv4 } from "uuid";

const { Meta } = Card;
const { Panel } = Collapse;

interface Props {}

export const RepsonsibleChoice = (props: Props) => {
  const location = useLocation();
  const params: any = useParams();
  const [slideIndex, setSlideIndex] = useState(1);

  useEffect(() => {
    showDivs(slideIndex);
  }, [slideIndex]);

  const plusDivs = (n: any) => {
    setSlideIndex((prevIndex) => prevIndex + n);
  };

  const showDivs = (n: any) => {
    const slides = document.getElementsByClassName("mySlides");

    if (slides.length === 0) {
      return; // No slides found
    }

    if (n > slides.length) {
      setSlideIndex(1);
    }

    if (n < 1) {
      setSlideIndex(slides.length);
    }

    for (let i = 0; i < slides.length; i++) {
      // @ts-ignore
      slides[i].style.display = i === slideIndex - 1 ? "block" : "none";
    }
  };
  const [responsible, setResponsible] = useState<Array<any>>([]);

  function useQueryy() {
    return new URLSearchParams(useLocation().search);
  }

  let query = useQueryy();
  const fetchResponsibleChoice = () => {
    let token = localStorage.getItem("token");
    return axiosInstance({
      method: "get",
      url: `/responsible_choice`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((res: any) => {
      const uniqueData = res.data.map((item: any, index: number) => ({ ...item, uniqueKey: index }));
      setResponsible(uniqueData.map((item:any )=> ({ ...item, key: uuidv4() })));
    });
  };

  const { isLoading, isError, data, error } = useQuery(
    ["companies", query.get("page")],
    () => fetchResponsibleChoice(),
    { keepPreviousData: true }
  );
  useEffect(() => {
    fetchResponsibleChoice();
    console.log(responsible);
  }, [location.pathname]);
  /*
    return;
    isLoading ? (
      <div className="loading_orders">
        <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
      </div>
    ) : (
      <div className="products_page_container">
        <div className={`container-fluid `}>
          <div style={{ padding: "25px" }}>
            <div>
              <div>
                {responsible &&
                  responsible.map((el: any) => {
                    return (
                      <div style={{ marginBottom: "25px" }}>
                        <Collapse
                          defaultActiveKey={["1"]}
                          expandIconPosition={"right"}
                          style={{}}
                        >
                          <Panel
                            header={el.name}
                            key="1"
                            id="panel-css"
                            style={{ backgroundColor: "white" }}
                          >
                            <div className="row">
                              <div
                                className="col-3"
                                style={{ textAlign: "left" }}
                              >
                                <p
                                  style={{
                                    wordWrap: "break-word",
                                    opacity: "0.5",
                                  }}
                                >
                                  {el.description || "No description"}
                                </p>
                              </div>
                              <div
                                className="col-9"
                                style={{ wordWrap: "break-word" }}
                              >
                                {" "}
                                <div>
                                  <Swiper
                                    slidesPerView={4.5}
                                    spaceBetween={30}
                                    navigation={true}
                                    pagination={false}
                                    className="mySwiper"
                                    style={{ overflow: "hidden" }}
                                  >
                                    {el?.products.map((prod: any) => {
                                      return (
                                        <>
                                          <SwiperSlide>
                                            {" "}
                                            <img
                                              src={
                                                process.env
                                                  .REACT_APP_STORAGE_URL +
                                                prod.front_image
                                              }
                                              width="100%"
                                              height={"50%"}
                                              style={{
                                                borderColor: "#FAFAFA",
                                              }}
                                            />
                                          </SwiperSlide>
                                        </>
                                      );
                                    })}
                                  </Swiper>
                                  <div style={{ textAlign: "right" }}>
                                    <Link
                                      to={
                                        "/responsible/" +
                                        el.id +
                                        "/" +
                                        el.fabric_type
                                      }
                                      className="btn"
                                    >
                                      See more
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Panel>
                        </Collapse>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
    ); */

  return isLoading ? (
    <div className="loading_orders">
      <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
    </div>
  ) : (
    <>
      <Helmet>
        <title>Responsible choice</title>
      </Helmet>
      <div className="products_page_container">
        <div className={`container-fluid `}>
          <div style={{ padding: "25px" }} key={uuidv4()}>
            <div key={uuidv4()}>
              <div>
                {/*      <div className="w3-content w3-display-container">
                                     Slide 1
                                    <div className="w3-display-container mySlides">
                                        <div className="row">

                                            <div className={"col-3"}>
                                                <Link
                                                    to={"#"} style={{
                                                    textAlign: "center",
                                                }}
                                                >
                                                    <img
                                                        src={
                                                            placeholder_jeans
                                                        }
                                                        width="100%"
                                                        height="300"
                                                    />
                                                    <span
                                                        style={{
                                                            fontSize: "16px",
                                                            textAlign: "center",
                                                        }}
                                                    >
                                                     code-name

                                                    </span>
                                                </Link>
                                            </div>
                                            <div className={"col-3"}>
                                                <Link
                                                    to={"#"} style={{
                                                    textAlign: "center",
                                                }}
                                                >
                                                    <img
                                                        src={
                                                            placeholder_jeans
                                                        }
                                                        width="100%"
                                                        height="300"
                                                    />
                                                    <span
                                                        style={{
                                                            fontSize: "16px",
                                                            textAlign: "center",
                                                        }}
                                                    >
                                                     code-name

                                                    </span>
                                                </Link>
                                            </div>
                                            <div className={"col-3"}>
                                                <Link
                                                    to={"#"} style={{
                                                    textAlign: "center",
                                                }}
                                                >
                                                    <img
                                                        src={
                                                            placeholder_jeans
                                                        }
                                                        width="100%"
                                                        height="300"
                                                    />
                                                    <span
                                                        style={{
                                                            fontSize: "16px",
                                                            textAlign: "center",
                                                        }}
                                                    >
                                                     code-name

                                                    </span>
                                                </Link>
                                            </div>
                                            <div className={"col-3"}>
                                                <Link
                                                    to={"#"} style={{
                                                    textAlign: "center",
                                                }}
                                                >
                                                    <img
                                                        src={
                                                            placeholder_jeans
                                                        }
                                                        width="100%"
                                                        height="300"
                                                    />
                                                    <span
                                                        style={{
                                                            fontSize: "16px",
                                                            textAlign: "center",
                                                        }}
                                                    >
                                                     code-name

                                                    </span>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>

                                     Slide 2
                                    <div className="w3-display-container mySlides">
                                        <div className="row">

                                            <div className={"col-3"}>
                                                <Link
                                                    to={"#"} style={{
                                                    textAlign: "center",
                                                }}
                                                >
                                                    <img
                                                        src={
                                                            placeholder_jeans
                                                        }
                                                        width="100%"
                                                        height="300"
                                                    />
                                                    <span
                                                        style={{
                                                            fontSize: "16px",
                                                            textAlign: "center",
                                                        }}
                                                    >
                                                     code-name

                                                    </span>
                                                </Link>
                                            </div>
                                            <div className={"col-3"}>
                                                <Link
                                                    to={"#"} style={{
                                                    textAlign: "center",
                                                }}
                                                >
                                                    <img
                                                        src={
                                                            placeholder_jeans
                                                        }
                                                        width="100%"
                                                        height="300"
                                                    />
                                                    <span
                                                        style={{
                                                            fontSize: "16px",
                                                            textAlign: "center",
                                                        }}
                                                    >
                                                     code-name

                                                    </span>
                                                </Link>
                                            </div>
                                            <div className={"col-3"}>
                                                <Link
                                                    to={"#"} style={{
                                                    textAlign: "center",
                                                }}
                                                >
                                                    <img
                                                        src={
                                                            placeholder_jeans
                                                        }
                                                        width="100%"
                                                        height="300"
                                                    />
                                                    <span
                                                        style={{
                                                            fontSize: "16px",
                                                            textAlign: "center",
                                                        }}
                                                    >
                                                     code-name

                                                    </span>
                                                </Link>
                                            </div>
                                            <div className={"col-3"}>
                                                <Link
                                                    to={"#"} style={{
                                                    textAlign: "center",
                                                }}
                                                >
                                                    <img
                                                        src={
                                                            placeholder_jeans
                                                        }
                                                        width="100%"
                                                        height="300"
                                                    />
                                                    <span
                                                        style={{
                                                            fontSize: "16px",
                                                            textAlign: "center",
                                                        }}
                                                    >
                                                     code-name

                                                    </span>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>


                                    <button className="w3-button w3-display-left w3-black" onClick={() => plusDivs(-1)}>
                                        &#10094;
                                    </button>
                                    <button className="w3-button w3-display-right w3-black" onClick={() => plusDivs(1)}>
                                        &#10095;
                                    </button>
                                </div>*/}
                {responsible &&
                  responsible.map((el: any, key: number) => {
                    return (
                      <>
                        {el && el.products.length > 0 ? (
                          <>
                            <div
                              className="row row-responsible-choice"
                              key={uuidv4()}
                            >
                              <div
                                className="col-12 col-md-4 p-md-2"
                                style={{ textAlign: "left" }}
                                key={uuidv4()}
                              >
                                <h1 className={"h1_reponsible_choice"}>
                                  {el.name}
                                </h1>
                                <div
                                  style={{
                                    wordWrap: "break-word",
                                    opacity: "1",
                                  }}
                                  className="paragraphe product_description_reponsible"
                                  dangerouslySetInnerHTML={{
                                    __html: el.description || "No description",
                                  }}
                                ></div>
                              </div>
                              <div
                                className="col-12 col-md-8"
                                style={{ wordWrap: "break-word" }}
                              >
                                {" "}
                                <div>
                                  {/*  <div key={el.id}>
                                                                                        <MySlider products={el.products} />
                                                                                    </div>*/}

                                  <Swiper
                                   modules={[Navigation, Keyboard]} // Pass the modules here
                                   slidesPerView={4}
                                   spaceBetween={30}
                                   navigation={true} // Enable navigation
                                   keyboard={true} // Enable keyboard navigation
                                    className="mySwiper"
                                    breakpoints={{
                                      360: { width: 360, slidesPerView: 1 },
                                      768: {
                                        width: 768,
                                        slidesPerView: 3.5,
                                      },
                                    }}
                                    style={{ overflow: "hidden" }}
                                    key={uuidv4()}
                                  >
                                    {el?.products.map(
                                      (prod: any, index: number) => {
                                        return (
                                          <div key={index} >
                                            <SwiperSlide className="sliderHeight">
                                              <div
                                                style={{
                                                  textAlign: "center",
                                                  width: "250px",
                                                }}
                                                key={uuidv4()}
                                              >
                                                <Link
                                                  to={`/product/details/${prod.fabric}/${prod.gendre}/${prod.category}/${prod.name}/${prod.id}`}
                                                  style={{
                                                    textAlign: "center",
                                                    color: "#182844",
                                                  }}
                                                  key={uuidv4()}
                                                >
                                                  <img
                                                    src={
                                                      prod.front_image
                                                        ? process.env
                                                            .REACT_APP_STORAGE_URL +
                                                          prod.front_image
                                                        : placeholder_jeans
                                                    }
                                                    width="270"
                                                    height="300"
                                                    /*
                                                                                                    className={"img-responsible-choice"}
*/
                                                  />
                                                  <p
                                                    style={{
                                                      fontSize: "12px",
                                                      textAlign: "center",
                                                      textTransform:
                                                        "capitalize",
                                                    }}
                                                  >
                                                    {prod.code} <br />
                                                    {prod.name}
                                                    <>
                                                      {prod.gender_id == 2 ? (
                                                        <>-Women</>
                                                      ) : prod.gender_id ==
                                                        1 ? (
                                                        <>-Men</>
                                                      ) : prod.gender_id ==
                                                        3 ? (
                                                        <>-Unisex</>
                                                      ) : prod.gender_id ==
                                                        4 ? (
                                                        <>-Kids</>
                                                      ) : prod.gender_id ==
                                                        5 ? (
                                                        <>-Boys</>
                                                      ) : prod.gender_id ==
                                                        6 ? (
                                                        <>-Girls</>
                                                      ) : (
                                                        ""
                                                      )}
                                                    </>
                                                  </p>
                                                </Link>
                                              </div>
                                            </SwiperSlide>
                                          </div>
                                        );
                                      }
                                    )}
                                  </Swiper>
                                  {/*   <div
                                                                                    style={{
                                                                                        marginTop: "20px",
                                                                                        textAlign: "right",
                                                                                    }}
                                                                                >
                                                                                    <Link
                                                                                        to={
                                                                                            "/responsible/" +
                                                                                            el.id +
                                                                                            "/" +
                                                                                            el.fabric_type +
                                                                                            "/" +
                                                                                            el.name
                                                                                        }
                                                                                        className="btn"
                                                                                    >
                                                                                        See more
                                                                                    </Link>
                                                                                </div>*/}
                                </div>
                              </div>
                            </div>
                            {/*      <div style={{marginBottom: "25px"}}>
                                                            <Collapse
                                                                defaultActiveKey={["1"]}
                                                                expandIconPosition={"right"}
                                                            >
                                                                <Panel
                                                                    header={el.name}
                                                                    key="1"
                                                                    id="panel-css"
                                                                    style={{
                                                                        backgroundColor: "white",
                                                                        fontSize: "22px",
                                                                    }}
                                                                >
                                                                    <div className="row">
                                                                        <div
                                                                            className="col-4"
                                                                            style={{textAlign: "left"}}
                                                                        >
                                                                            <div
                                                                                style={{
                                                                                    wordWrap: "break-word",
                                                                                    opacity: "1",
                                                                                }}
                                                                                className="paragraphe product_description"
                                                                                dangerouslySetInnerHTML={{
                                                                                    __html:
                                                                                        el.description || "No description",
                                                                                }}
                                                                            ></div>
                                                                        </div>
                                                                        <div
                                                                            className="col-8"
                                                                            style={{wordWrap: "break-word"}}
                                                                        >
                                                                            {" "}
                                                                            <div>
                                                                                    <div key={el.id}>
                                                                                        <MySlider products={el.products} />
                                                                                    </div>

                                                                                <Swiper
                                                                                    slidesPerView={4.5}
                                                                                    spaceBetween={30}
                                                                                    navigation={true}
                                                                                    pagination={false}
                                                                                    className="mySwiper"

                                                                                    style={{overflow: "hidden"}}
                                                                                >
                                                                                    {el?.products.map((prod: any) => {
                                                                                        return (
                                                                                            <>
                                                                                                <SwiperSlide
                                                                                                    className="sliderHeight">
                                                                                                    <div>
                                                                                                        <Link
                                                                                                            to={`/product/details/${prod.fabric}/${prod.gendre}/${prod.category}/${prod.name}/${prod.id}`}
                                                                                                            style={{
                                                                                                                textAlign: "center",
                                                                                                            }}
                                                                                                        >
                                                                                                            <img
                                                                                                                src={
                                                                                                                    prod.front_image
                                                                                                                        ? process.env
                                                                                                                            .REACT_APP_STORAGE_URL +
                                                                                                                        prod.front_image
                                                                                                                        : placeholder_jeans
                                                                                                                }
                                                                                                                width="100%"
                                                                                                                height="300"
                                                                                                            />
                                                                                                            <span
                                                                                                                style={{
                                                                                                                    fontSize: "16px",
                                                                                                                    textAlign: "center",
                                                                                                                }}
                                                                                                            >
                                                      {prod.code}-{prod.name}
                                                                                                                <>
                                                        {prod.gender_id == 2 ? (
                                                            <>-Women</>
                                                        ) : prod.gender_id ==
                                                        1 ? (
                                                            <>-Men</>
                                                        ) : prod.gender_id ==
                                                        3 ? (
                                                            <>-Unisex</>
                                                        ) : prod.gender_id ==
                                                        4 ? (
                                                            <>-Kids</>
                                                        ) : prod.gender_id ==
                                                        5 ? (
                                                            <>-Boys</>
                                                        ) : prod.gender_id ==
                                                        6 ? (
                                                            <>-Girls</>
                                                        ) : (
                                                            ""
                                                        )}
                                                      </>
                                                    </span>
                                                                                                        </Link>
                                                                                                    </div>
                                                                                                </SwiperSlide>
                                                                                            </>
                                                                                        );
                                                                                    })}
                                                                                </Swiper>
                                                                                <div
                                                                                    style={{
                                                                                        marginTop: "20px",
                                                                                        textAlign: "right",
                                                                                    }}
                                                                                >
                                                                                    <Link
                                                                                        to={
                                                                                            "/responsible/" +
                                                                                            el.id +
                                                                                            "/" +
                                                                                            el.fabric_type +
                                                                                            "/" +
                                                                                            el.name
                                                                                        }
                                                                                        className="btn"
                                                                                    >
                                                                                        See more
                                                                                    </Link>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Panel>
                                                            </Collapse>
                                                        </div>*/}
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
