import React, {useEffect, useRef, useState} from "react";
import { Row, Col, Drawer, Space, Tooltip, Popconfirm, Modal } from "antd";
import { useForm } from "react-hook-form";
import { LazerOpenModalButton } from "./buttons/LazerOpenModalButton";
import { FrontLazerCanvas } from "./canvas/FrontLazerCanvas";
import { BackLazerCanvas } from "./canvas/BackLazerCanvas";
import { LazerCanvasConfigurator } from "./canvas-configurators/LazerCanvasConfigurator";
import { FrontLazerDamageCanvas } from "./canvas/FrontLazerStep2";
import { BackLazerDamageCanvas } from "./canvas/BackLazerStep2";
import { LazerCanvasStep2 } from "./canvas/LazerCanvasStep2";
import { EditBrushIcon } from "../icons/EditBrushIcon";
import { DamageIndicatorIcon } from "../icons/DamageIndicatorIcon";
import html2canvas from "html2canvas";
//@ts-ignore
import { useScreenshot, createFileName } from "use-react-screenshot";
import { OrderButton } from "./buttons/OrderButton";
import { useSelector } from "react-redux";
import { InfoCircleOutlined, CloseOutlined } from "@ant-design/icons";
import { CustomizationProduct } from "./canvas/CustomizationProduct";
import "./canvas/lazerCanvas.scss";
import { Spinner } from "../../Spinner";
import $ from 'jquery';

export type ILazerFormProps = {
  handleSceenShorts: any;
  handleChange: any;
  handleSceenShortsTypeLazer: any;
  handleSceenShortsRight: any;
  handleSceenShortsLeft: any;
  handleImageTypes: (laser_file_type_design: {
    file: any;
    color: string;
  }) => void;

  deleteImageTypes: any;
  globalData: any;
  mouseMoveFrontLazer: (mousePosition: { x: number; y: number }) => void;
  mouseMoveBackLazer: (mousePosition: { x: number; y: number }) => void;

  mouseClickFrontLazerDesign: (mousePosition: {
    id: string;
    x: number;
    y: number;
    color: string;
  }) => void;
  mouseClickBackLazerDesign: (mousePosition: {
    id: string;
    x: number;
    y: number;
    color: string;
  }) => void;
  deletePositionFiles: (id: string, attribute: string) => void;
  startCustom: any;
  openDrawerCustom: any;
};
const LazerForm: React.FC<ILazerFormProps> = ({
  handleSceenShorts,
  startCustom,
  handleChange,
  handleSceenShortsTypeLazer,
  handleImageTypes,
  handleSceenShortsRight,
  handleSceenShortsLeft,

  deleteImageTypes,
  globalData,
  mouseMoveFrontLazer,
  mouseMoveBackLazer,
  mouseClickFrontLazerDesign,
  mouseClickBackLazerDesign,
  deletePositionFiles,
  openDrawerCustom,
}) => {
  const laserTypeRef = React.useRef(null);
  const laserRef = React.useRef(null);
  const [imageTypeLaser, takeScreenshotTypeLaser] = useScreenshot();
  const [imageLaser, takeScreenshotLaser] = useScreenshot();
  const [clearButton, setClear] = React.useState(0);
  const [activeFront, setActiveFront] = React.useState<any>(false);
  const [isLoading, setLoading] = React.useState<any>(false);
  const [pluginLoaded, setPluginLoaded] = useState(false);
  const Clear = () => {
    setClear(clearButton + 1);
  };
  const [drawerOpened, setDrawerOpened] = React.useState(false);
  const [done, setDone] = React.useState(false);
  // const { register, control, handleSubmit, watch, errors } = useForm();
  const [current, setCurrent] = React.useState(1);
  const [ImgLaser, setImgLaser] = React.useState<any>();
  const [ImgTypeLaser, setImgTypeLaser] = React.useState<any>();
  const [deleteBrush, setDeleteBrush] = React.useState(false);
  const lazerbtnNext = document.getElementById("lazer-canvas-btn-next");
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [open360, setOpen360] = React.useState(false);

  useEffect(() => {
    setDrawerOpened(startCustom);
    console.log(startCustom);
    setActiveFront(false);
  }, [startCustom]);
  const download = (image: any, { name = "img", extension = "png" } = {}) => {
    const a = document.createElement("a");
    a.href = image;
    a.download = createFileName(extension, name);
    a.click();
  };

  useEffect(() => {
    const loadScript = (src:any, id:any) => {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = src;
        script.id = id;
        script.onload = () => {
          console.log(`Loaded script: ${src}`);
          resolve(script);
        };
        script.onerror = () => {
          console.error(`Script load error for ${src}`);
          reject(new Error(`Script load error for ${src}`));
        };
        document.body.appendChild(script);
      });
    };

    const loadScripts = async () => {
      try {
        if (!document.querySelector('script#jquery-script')) {
          await loadScript('https://cdnjs.cloudflare.com/ajax/libs/jquery/2.2.4/jquery.min.js', 'jquery-script');
        }
        if (!document.querySelector('script#turntable-plugin-script')) {
          await loadScript('https://g.tutorialjinni.com/PolarNotion/turntable/js/main.min.js', 'turntable-plugin-script');
        }
        setPluginLoaded(true);
      } catch (error) {
        console.error('Error loading scripts:', error);
      }
    };

    loadScripts();

    return () => {
      const jqueryScript = document.querySelector('script#jquery-script');
      const pluginScript = document.querySelector('script#turntable-plugin-script');
      if (jqueryScript) document.body.removeChild(jqueryScript);
      if (pluginScript) document.body.removeChild(pluginScript);
    };
  }, []);



  useEffect(() => {
    if (pluginLoaded && open360) {
      const element = document.getElementById('myTurntable');
      //@ts-ignore
      if (element && window.jQuery && typeof window.jQuery(element).turntable === 'function') {
        console.log('Initializing turntable');
        setTimeout(() => {
          //@ts-ignore
          window.jQuery(element).turntable({
            autoRotate: true,
            rotateInterval: 1000, // 1 second interval
          });
        }, 100); // Add a slight delay to ensure the modal is fully rendered
      } else {
        console.error('Turntable plugin is not available');
      }
    }
  }, [pluginLoaded, open360]);
     const screenShotHandlerTypeLaser = async () => {
    console.log("capture-design");
    const cap: any = document.querySelector("#capteur");
    html2canvas(cap, { useCORS: true }).then(async (canvas) => {
      var fullQuality = canvas.toDataURL("image/jpeg", 1.0);
      const filename = (Math.random() + 1).toString(36).substring(7);
      const file = await dataUrlToFile(fullQuality, `${filename}.jpg`);
      downloadImage(fullQuality, filename);
      handleSceenShortsTypeLazer("laser_images_design", [file]);
    });
  };

  /*const screenShotHandlerTypeLaser = async () => {
    const captureAndProcessScreenshot = async (
      selector: string,
      handleFunction: (type: string, files: File[]) => void,
      type: string
    ) => {
      const cap: HTMLElement | null = document.querySelector(selector);
      if (cap) {
        console.log(
          `Capturing screenshot for element with selector: ${selector}`
        );
        try {
          const canvas = await html2canvas(cap, { useCORS: true });
          const fullQuality = canvas.toDataURL("image/jpeg", 1.0);
          const filename = `${(Math.random() + 1)
            .toString(36)
            .substring(7)}.jpg`;



          console.log(
            `Screenshot captured and converted to data URL: ${fullQuality}`
          );
          const file = await dataUrlToFile(fullQuality, filename);
       //   downloadImage(fullQuality, filename);
          handleFunction(type, [file]);
        } catch (error) {
          console.error(`Error capturing screenshot for ${selector}:`, error);
        }
      } else {
        console.error(`Element with selector ${selector} not found`);
      }
    };


    const captureAndProcessScreenFront = async (
        selector: string,
        handleFunction: (type: string, files: File[]) => void,
        type: string
    ) => {
      const cap: HTMLElement | null = document.querySelector(selector);
      if (cap) {
        console.log(
            `Capturing screenshot for element with selector: ${selector}`
        );
        try {
          const canvas = await html2canvas(cap, { useCORS: true });
          const fullQuality = canvas.toDataURL("image/jpeg", 1.0);
          const filename = `1.jpg`;



          console.log(
              `Screenshot captured and converted to data 1: ${fullQuality}`
          );
          localStorage.setItem('frontview',fullQuality)
          const file = await dataUrlToFile(fullQuality, filename);
       //     downloadImage(fullQuality, filename);
          handleFunction(type, [file]);
        } catch (error) {
          console.error(`Error capturing screenshot for ${selector}:`, error);
        }
      } else {
        console.error(`Element with selector ${selector} not found`);
      }
    };

    const captureAndProcessScreenBack = async (
        selector: string,
        handleFunction: (type: string, files: File[]) => void,
        type: string
    ) => {
      const cap: HTMLElement | null = document.querySelector(selector);
      if (cap) {
        console.log(
            `Capturing screenshot for element with selector: ${selector}`
        );
        try {
          const canvas = await html2canvas(cap, { useCORS: true });
          const fullQuality = canvas.toDataURL("image/jpeg", 1.0);
          const filename = `2.jpg`;

          console.log(
              `Screenshot captured and converted to data 1: ${fullQuality}`
          );
          localStorage.setItem('backview',fullQuality)
          const file = await dataUrlToFile(fullQuality, filename);
       //   downloadImage(fullQuality, filename);
          handleFunction(type, [file]);
        } catch (error) {
          console.error(`Error capturing screenshot for ${selector}:`, error);
        }
      } else {
        console.error(`Element with selector ${selector} not found`);
      }
    };

    const captureAndProcessScreenLeft = async (
        selector: string,
        handleFunction: (type: string, files: File[]) => void,
        type: string
    ) => {
      const cap: HTMLElement | null = document.querySelector(selector);
      if (cap) {
        console.log(
            `Capturing screenshot for element with selector: ${selector}`
        );
        try {
          const canvas = await html2canvas(cap, { useCORS: true });
          const fullQuality = canvas.toDataURL("image/jpeg", 1.0);
          const filename = `3.jpg`;

          console.log(
              `Screenshot captured and converted to data 1: ${fullQuality}`
          );
          localStorage.setItem('leftview',fullQuality)
          const file = await dataUrlToFile(fullQuality, filename);
       //   downloadImage(fullQuality, filename);
          handleFunction(type, [file]);
        } catch (error) {
          console.error(`Error capturing screenshot for ${selector}:`, error);
        }
      } else {
        console.error(`Element with selector ${selector} not found`);
      }
    };

    const captureAndProcessScreenRight = async (
        selector: string,
        handleFunction: (type: string, files: File[]) => void,
        type: string
    ) => {
      const cap: HTMLElement | null = document.querySelector(selector);
      if (cap) {
        console.log(
            `Capturing screenshot for element with selector: ${selector}`
        );
        try {
          const canvas = await html2canvas(cap, { useCORS: true });
          const fullQuality = canvas.toDataURL("image/jpeg", 1.0);
          const filename = `4.jpg`;

          console.log(
              `Screenshot captured and converted to data 1: ${fullQuality}`
          );
          localStorage.setItem('rightview',fullQuality)
          const file = await dataUrlToFile(fullQuality, filename);
        //  downloadImage(fullQuality, filename);
          handleFunction(type, [file]);
        } catch (error) {
          console.error(`Error capturing screenshot for ${selector}:`, error);
        }
      } else {
        console.error(`Element with selector ${selector} not found`);
      }
    };

   await captureAndProcessScreenshot(
      "#capteur",
      handleSceenShortsTypeLazer,
      "laser_images_design"
    );

   // await delay(5000);

    await captureAndProcessScreenFront(
      "#capteur_custom_front",
      handleSceenShortsTypeLazer,
      "laser_images_design"
    );
   // await delay(1000);
    await captureAndProcessScreenBack(
      "#capteur_custom_back",
      handleSceenShortsTypeLazer,
      "laser_images_drawing"
    );
   await delay(1000);

    await captureAndProcessScreenLeft(


    "#capteur_custom_left",
        handleSceenShortsLeft,
        "laser_images_left"
    );

    await delay(1000);

    await captureAndProcessScreenRight(
        "#capteur_custom_right",
        handleSceenShortsRight,
        "laser_images_right"
    );
  };*/

  /*const screenShotHandlerTypeLaser = async () => {
    const captureAndStoreScreenshot = async (selector:any, key:any) => {
      const cap = document.querySelector(selector);
      if (cap) {
        console.log(`Capturing screenshot for element with selector: ${selector}`);
        try {
          // Adding a small delay to ensure the element is fully rendered
          await new Promise(resolve => setTimeout(resolve, 500));

          const canvas = await html2canvas(cap, { useCORS: true });
          const fullQuality = canvas.toDataURL("image/jpeg", 1.0);

          if (fullQuality.startsWith('data:image/jpeg;base64,')) {
            console.log(`Screenshot captured successfully for ${selector}`);
            localStorage.setItem(key, fullQuality);
          } else {
            console.error(`Failed to capture a valid screenshot for ${selector}`);
          }
        } catch (error) {
          console.error(`Error capturing screenshot for ${selector}:`, error);
        }
      } else {
        console.error(`Element with selector ${selector} not found`);
      }
    };

    await captureAndStoreScreenshot("#capteur_custom_front", 'frontview');
    await captureAndStoreScreenshot("#capteur_custom_back", 'backview');
    await captureAndStoreScreenshot("#capteur_custom_left", 'leftview');
    await captureAndStoreScreenshot("#capteur_custom_right", 'rightview');
  };
*/

  function delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  const screenShotHandlerTypeLaser_right = async () => {
    console.log("capture-design");
    const cap: any = document.querySelector("#capteur_custom_right");
    html2canvas(cap, { useCORS: true }).then(async (canvas) => {
      var fullQuality = canvas.toDataURL("image/jpeg", 1.0);
      const filename = (Math.random() + 1).toString(36).substring(7);
      const file = await dataUrlToFile(fullQuality, `${filename}.jpg`);
      downloadImage(fullQuality, filename);
      handleSceenShortsRight("images_design_right", [file]);
    });
  };

  const screenShotHandlerTypeLaser_left = async () => {
    console.log("capture-design");
    const cap: any = document.querySelector("#capteur_custom_left");
    html2canvas(cap, { useCORS: true }).then(async (canvas) => {
      var fullQuality = canvas.toDataURL("image/jpeg", 1.0);
      const filename = (Math.random() + 1).toString(36).substring(7);
      const file = await dataUrlToFile(fullQuality, `${filename}.jpg`);
    //  downloadImage(fullQuality, filename);
      handleSceenShortsLeft("images_design_left", [file]);
    });
  };

  function downloadImage(url: any, filename: any) {
    // Create a temporary anchor element
    const link = document.createElement("a");
    link.href = url;
    link.download = filename;

    // Programmatically trigger the download
    link.click();

    // Cleanup
    link.remove();
  }
  const screenShotLaserHandler = async () => {
    console.log("capture-drawing");

    const cap: any = document.querySelector("#capteur_custom_back");
    html2canvas(cap, { useCORS: true }).then(async (canvas) => {
      var fullQuality = canvas.toDataURL("image/jpeg", 1.0);
      const filename = (Math.random() + 1).toString(36).substring(7);
      const file = await dataUrlToFile(fullQuality, `${filename}.jpg`);

      downloadImage(fullQuality, filename);
      handleSceenShorts("laser_images_drawing", [file]);
    });

    // setCurrent(current + 1);
  };

  const screenShotLaserHandlerBack = async () => {
    const cap_back: any = document.querySelector("#capteur_custom_back");

    html2canvas(cap_back, { useCORS: true }).then(async (canvas) => {
      var fullQuality = canvas.toDataURL("image/jpeg", 1.0);
      const filename = (Math.random() + 1).toString(36).substring(7);
      const file = await dataUrlToFile(fullQuality, `${filename}.jpg`);

      /*       handleSceenShortsBack("laser_images_drawing_back", [file]);
       */
    });
  };
  const [close, setClose] = React.useState(false);
  async function dataUrlToFile(
    dataUrl: string,
    fileName: string
  ): Promise<File> {
    const res: Response = await fetch(dataUrl);
    const blob: Blob = await res.blob();
    return new File([blob], fileName, { type: "image/png" });
  }

  // React.useEffect(() => {
  //   if (ImgTypeLaser) {
  //     handleSceenShortsTypeLazer(
  //       "laser_type_images",
  //       dataURLtoFile(ImgTypeLaser, "Test.jpg")
  //     );
  //     // download the image async if we want
  //     //download(image, { name: "lorem-ipsum", extension: "png" });
  //     // setDrawerOpened(false);
  //   }
  // }, [ImgTypeLaser]);

  // React.useEffect(() => {
  //   if (ImgLaser) {
  //     handleSceenShorts("laser_images", dataURLtoFile(ImgLaser, "Test.jpg"));
  //   }
  // }, [ImgLaser]);

  const [lazerSize, setLazerSize] = React.useState(20);
  const [erase, setErase] = React.useState(false);

  const [lazerIntensity, setLazerIntensity] = React.useState(1);
  const [paintLazer, setPaintLaser] = React.useState(true);
  const { selectedProduct } = useSelector((state: any) => state.products);
  const [mousePositionArray, setmousePositionArray] = React.useState([]);
  const [frontView, setFrontView] = React.useState<any>(null);
  const [backView, setBackView] = React.useState<any>("");
  const [leftView, setLeftView] = React.useState<any>("");
  const [rightView, setRightView] = React.useState<any>("");

  useEffect(() => {
    const storedValue1 = localStorage.getItem("frontview");
    const storedValue2 = localStorage.getItem("backview");
    const storedValue3 = localStorage.getItem("leftview");
    const storedValue4 = localStorage.getItem("rightview");
    setFrontView(storedValue1);
    setBackView(storedValue2);
    setLeftView(storedValue3);
    setRightView(storedValue4);
  }, []);

  useEffect(() => {
    console.log("frontView:", frontView);
  }, [frontView,backView,leftView,rightView]);
  const closeDrawer = () => {
    setDrawerOpened(false);
    setCurrent(1);
  };

  const screenShot = async () => {
    if (current === 2) {
      // screenShotHandlerTypeLaser();

      // screenShotLaserHandler();

      setActiveFront(true);
      // setLoading(true);
      await delay(1000);
      screenShotHandlerTypeLaser();

      await delay(1000);
      setDrawerOpened(false);
      openDrawerCustom(false);
      setLoading(false);
    } else if (current === 1) {
      setActiveFront(true);
      // setLoading(true);
      await delay(1000);
      screenShotHandlerTypeLaser();

      await delay(1000);
      setDrawerOpened(false);
      openDrawerCustom(false);
      setLoading(false);
    }
    /*  screenShotLaserHandler();
    setDrawerOpened(false); */
  };

  const [open, setOpen] = React.useState(false);

  const showModal = () => {
    setOpen(true);
  };

  const showModal360 = () => {
  handleSceenShortsTypeLazer()
    const storedValue1 = localStorage.getItem("frontview");
    const storedValue2 = localStorage.getItem("backview");
    const storedValue3 = localStorage.getItem("leftview");
    const storedValue4 = localStorage.getItem("rightview");
    setFrontView(storedValue1);
    setBackView(storedValue2);
    setLeftView(storedValue3);
    setRightView(storedValue4);
/*    screenShot()
    delay(8000)*/
    setOpen360(true);
  };


  const handleCancel = () => {
    setOpen(false);
  };
  const handleCancel360 = () => {
    setOpen360(false);
  };

  return (
    <div>
      <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
        {/*   <Row>
          <LazerOpenModalButton
            title="Customize your product!"
            onClick={() => setDrawerOpened(true)}
            type="lazer"
          />
        </Row> */}
        {/*   <button
          type="button"
          className="order-btn-h"
          onClick={() => setDrawerOpened(true)}
        >
          Customize your product!
        </button>*/}
        {/*     <Row>
          <textarea
            placeholder="More Details"
            className="textarea-custom"
            rows={3}
            name="laser_details"
            onChange={(e: any) => handleChange(e)}
            value={globalData.laser_details}
            // ref={register({ required: false })}
          />
        </Row> */}
        {/* <div style={{ alignSelf: "flex-end", marginTop: "auto" }}>
          <OrderButton title="Next" />
        </div> */}
      </div>
      <Drawer
        //height={window.innerWidth < 769 ? window.innerHeight - 80 : "100vh"}
        className="height-drawer-custom"
        maskClosable={false}
        placement="bottom"
        visible={drawerOpened}
        closable={false}
        onClose={() => closeDrawer()}
        title={
          <div className="d-flex justify-content-center align-items-center">
            <h2 className="text-center">
              {/*  Laser Intensity {current === 1 ? "Drawing" : "Design"}{" "} */}
              Customized Your Product
            </h2>
            <Space className="ml-auto">

              <button
                  type="submit"
                  onClick={showModal360}
                  style={{
                    background: "white",
                    color: "#182844",
                    border:"1px solid #182844",
                    borderRadius: 5,
                    height: 40,
                    paddingInline: 24,
                    marginRight: 8,
                    fontSize: "16px",
                  }}
              >
                View 360
              </button>

              <button
                  type="submit"
                  onClick={screenShot}
                  /*  className="order-btn-h" */
                  style={{
                    background: "#182844",
                    color: "white",
                    borderRadius: 5,
                    height: 40,
                    paddingInline: 24,
                    marginRight: 8,
                    fontSize: "16px",
                  }}
              >
                Confirm and close
              </button>
              <button
                  type="button"
                  onClick={showModal}
                  /* className="order-btn-h outlined-btn-h" */
                  style={{
                    background: "#e3e0e0",
                    color: "white",
                    borderRadius: 5,
                    height: 40,
                    paddingInline: 16,
                    paddingBottom: 7,
                    fontSize: "16px",
                  }}
              >
                <CloseOutlined
                    style={{
                      color: "#182844",

                      fontSize: "16px",
                    }}
                />
              </button>


              <Modal
                  title="Product Viewer"
                  visible={open360}
                  maskClosable={false}
                  closable={false}
                  centered
                  onCancel={handleCancel360}
                  width={"100%"}

                  footer={[
                    <button key="back" onClick={handleCancel360} style={{ borderRadius: 5 }}>
                      Cancel
                    </button>,
                  ]}
                  afterClose={() => setPluginLoaded(false)} // Reset the plugin loaded state after closing
              >
                <div id="myTurntable" className="turntable" style={{display:"flex",justifyContent:"center",textAlign:"center"}} >
                  <ul>
               <li data-img-src={frontView}></li>

                    <li data-img-src={leftView}></li>


                    <li data-img-src={backView}></li>
                    <li data-img-src={rightView}></li>
                  {/*   <li data-img-src="https://via.placeholder.com/950/FF0000/FFFFFF?text=1"></li>
                    <li data-img-src="https://via.placeholder.com/150/00FF00/FFFFFF?text=2"></li>
                    <li data-img-src="https://via.placeholder.com/150/0000FF/FFFFFF?text=3"></li>*/}
                  </ul>
                </div>
              </Modal>

              <Modal
                  title="Changes not saved"
                  visible={open}
                  maskClosable={false}
                  closable={false}
                  centered
                  onCancel={handleCancel}
                  footer={[
                    <button
                        onClick={() => {
                          handleCancel();
                          screenShot();
                          openDrawerCustom(false);
                        }}
                        key="submit"
                        style={{
                          background: "#182844",
                          color: "white",
                          borderRadius: 5,
                          height: 40,
                          paddingInline: 24,
                          marginRight: 8,
                        }}
                    >
                      Confirm and close
                    </button>,

                    <button
                        onClick={() => {
                          openDrawerCustom(false);
                          setDrawerOpened(false);
                          /* Clear(); */
                          localStorage.removeItem(
                              "canvasDataFront" + selectedProduct.code
                          );
                          localStorage.removeItem(
                              "canvasDataBack" + selectedProduct.code
                          );
                          handleCancel();
                        }}
                        key="submit"
                        style={{
                          background: "white",
                          border: "1px solid #E0E0E0",
                          borderRadius: 5,
                          height: 40,
                          paddingInline: 24,
                          marginRight: 8,
                        }}
                    >
                      Discard changes
                    </button>,

                    <button
                        key="back"
                        onClick={handleCancel}
                        style={{borderRadius: 5}}
                    >
                      Cancel
                    </button>,
                  ]}
              >
                <p>Would you like to confirm your changes?</p>
              </Modal>
              {/*  {current === 1 ? (
                <button
                  type="button"
                  onClick={screenShotLaserHandler}
                  className="order-btn-h outlined-btn-h"
                >
                  Next
                </button>
              ) : (
                ""
              )} */}
            </Space>
          </div>
        }
      >
        {/* <Row justify="center" style={{ width: "100%" }}>
          <h1>Identify Lazer Area</h1>
        </Row> */}
        {current === 1 ? (
            <>
              {/*   <Row
              className="container-configurator pt-4"
              ref={laserRef}
              id="capture"
            >
              <Col span={11}>
                <FrontLazerCanvas
                  size={lazerSize}
                  intensity={lazerIntensity}
                  paintLazer={paintLazer}
                  mousePositionArray={mousePositionArray}
                  mouseMoveFrontLazer={mouseMoveFrontLazer}
                  initialDrawing={globalData.tmp_laser_images_drawing_front}
                  erase={erase}
                  close={close}
                  clearButton={clearButton}
                />
              </Col>

              <div className="configurator">
                <LazerCanvasConfigurator
                  setLazerIntensity={setLazerIntensity}
                  setLazerSize={setLazerSize}
                  setPaintLaser={setPaintLaser}
                  paintLazer={paintLazer}
                  setCurrent={setCurrent}
                  current={current}
                  setEraser={setErase}
                />
              </div>

              <Col span={11}>
                <BackLazerCanvas
                  size={lazerSize}
                  intensity={lazerIntensity}
                  mouseMoveBackLazer={mouseMoveBackLazer}
                  initialDrawing={globalData.tmp_laser_images_drawing_back}
                  erase={erase}
                  clearButton={clearButton}
                />
              </Col>
            </Row> */}
            <Row className="pt-2" ref={laserRef} id="capture">
              {!isLoading ? (
                <>
                  <CustomizationProduct
                    size={lazerSize}
                    intensity={lazerIntensity}
                    paintLazer={paintLazer}
                    mousePositionArray={mousePositionArray}
                    mouseMoveFrontLazer={mouseMoveFrontLazer}
                    handleImageTypes={handleImageTypes}
                    initialDrawing={globalData.tmp_laser_images_drawing_front}
                    erase={erase}
                    close={close}
                    clearButton={clearButton}
                    activeFront={activeFront}
                  />
                </>
              ) : (
                <>
                  {" "}
                  <div
                    className="canvas-container"
                    style={{ marginTop: "0px" }}
                  >
                    <Spinner></Spinner>
                  </div>
                </>
              )}
            </Row>
          </>
        ) : (
          current === 2 && (
            /*  <Row
              ref={laserTypeRef}
              id="capture-design"
              className="container-configurator pt-4"
            >
              <LazerCanvasStep2
                setPaintLaser={setPaintLaser}
                setCurrent={setCurrent}
                current={current}
                screenShotHandlerTypeLaser={screenShotHandlerTypeLaser}
                deleteBrush={deleteBrush}
                handleImageTypes={handleImageTypes}
                deleteImageTypes={deleteImageTypes}
                mouseClickFrontLazerDesign={mouseClickFrontLazerDesign}
                mouseClickBackLazerDesign={mouseClickBackLazerDesign}
                initialDesign={{
                  images: globalData.laser_type_images || [],
                  front: globalData.tmp_laser_images_design_front || [],
                  back: globalData.tmp_laser_images_design_back || [],
                }}
                deletePositionFiles={(id: string, attribute: string) =>
                  deletePositionFiles(id, attribute)
                }
              />
              <Col span={21} style={{ marginTop: "200px" }}></Col>
            </Row> */
            <Row
              className="container-configurator pt-4"
              ref={laserRef}
              id="capture"
            >
              <Col span={11}>
                <FrontLazerCanvas
                  size={lazerSize}
                  intensity={lazerIntensity}
                  paintLazer={paintLazer}
                  mousePositionArray={mousePositionArray}
                  mouseMoveFrontLazer={mouseMoveFrontLazer}
                  initialDrawing={globalData.tmp_laser_images_drawing_front}
                  erase={erase}
                  close={close}
                  clearButton={clearButton}
                />
              </Col>

              {/*        <button
              className="choose-cursor-btn-configurator mt-3"
              onClick={screenShotLaserHandler}
            >
              <DamageIndicatorIcon />
            </button> 
            <div className="vertical-line"></div>  */}
              <div className="configurator">
                <LazerCanvasConfigurator
                  setLazerIntensity={setLazerIntensity}
                  setLazerSize={setLazerSize}
                  setPaintLaser={setPaintLaser}
                  paintLazer={paintLazer}
                  setCurrent={setCurrent}
                  current={current}
                  setEraser={setErase}
                />
              </div>

              <Col span={11}>
                <BackLazerCanvas
                  size={lazerSize}
                  intensity={lazerIntensity}
                  mouseMoveBackLazer={mouseMoveBackLazer}
                  initialDrawing={globalData.tmp_laser_images_drawing_back}
                  erase={erase}
                  clearButton={clearButton}
                />
              </Col>
            </Row>
          )
        )}

        {/* <Row
          justify="center"
          align="middle"
          // marginTop: "2.5rem"
          style={{
            zIndex: 99999999,
            marginTop: window.innerWidth < 769 ? "7.8rem" : "0",
          }}
        >
          <button
            type="button"
            onClick={() => setDrawerOpened(false)}
            className="order-btn-h outlined-btn-h"
          >
            Discard
          </button>
          <button type="submit" onClick={screenShot} className="order-btn-h">
            Confirm
          </button>
        </Row> */}
      </Drawer>
    </div>
  );
};

export { LazerForm };
