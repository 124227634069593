import sustainableLogo from "../../img/sustainableLogo.svg";
import placeholder_jeans from "../../img/placeholder_jeans.svg";
import customizeIcon from "../../img/customize_icon.svg";
import icon3D from "../../img/3d_icon.svg";
import { Button, Card, Modal, Popconfirm, Select } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  addProductToSelection,
  removeProductFromSelection,
} from "../../actions/myselection-actions/actions";
import { faHeart as farHeart } from "@fortawesome/free-regular-svg-icons";
import {
  faCaretDown,
  faHeart,
  faSpinner,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useHistory, useParams } from "react-router-dom";
import useWindowWidth from "../../config/useWindowWidth";
import { Draggable } from "react-beautiful-dnd";
import { useEffect, useState } from "react";
import LazyImg from "../Library/LazyImage";
import watermak from "../../img/watermark.png";
import moment from "moment";
import { MyNotif } from "../NotifIcons/NotifIcons";
import axiosInstance from "../../config/axios-instance";
import { useQuery } from "react-query";

const { Meta } = Card;

const ExampleProductCard = ({
  index,
  productPage,
  product,
  item,
  foryou,
  company,
  clients,
}: any) => {
  const [checkedCheck, setCheckedCheck] = useState(false);
  const [companies, setCompanies] = useState<any>(null);
  const [FY, setFY] = useState<any>(null);
  const [starCheck, setStarCheck] = useState<any>(false);
  const { user, isAdmin } = useSelector((state: any) => state.auth);
  const selection = useSelector((state: any) => state.selection);
  const folders = useSelector((state: any) => state.folders);
  const dispatch = useDispatch();
  const params: any = useParams();
  const history = useHistory();
  const width = useWindowWidth();
  const colorStroke = () => {
    let sustain = product.labels.find((el: any) => el.name === "SUSTAINABLE");
    let newLabel = product.labels.find((el: any) => el.name === "NEW");
    let favorite = product.labels.find(
      (el: any) => el.name === "DEMCO'S FAVORITE"
    );
    if (product.labels && newLabel) {
      return newLabel.name;
    } else if (product.labels && sustain) {
      return sustain.name;
    } else if (product.labels && favorite) {
      return favorite.name;
    } else {
      return "none";
    }
  };

  const getCompanies = async () => {
    const company_id = localStorage.getItem("company_id");
    let token = localStorage.getItem("token");
    if (company_id) {
      await axiosInstance({
        method: "get",
        url: `/admin/companies/`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then(function (response) {
        setCompanies(response.data);
      });
      return "ok";
    }
  };

 /*  205 
  288
  256
  512
  410
  */

  /*  const company_id = localStorage.getItem("company_id");
  const {
    data: foryouc,
    isLoading: isLoading,
    refetch: refetch,
  } = useQuery("foryouc", getForYouCompany, {
    refetchOnWindowFocus: false,
  }); */
  useEffect(() => {
    if (productPage) {
      if (folders.selectedProducts.includes(product.id)) {
        setCheckedCheck(true);
      } else {
        setCheckedCheck(false);
      }
    }
  }, [folders.selectedProducts]);

  useEffect(() => {
    var filteredProduct = foryou?.products.filter(
      (prod: any) => prod.id === product.id
    );

    if (filteredProduct) {
      setFY(filteredProduct[0]?.id);
    }
  }, [foryou]);

  const draggableProductData = {
    draggableId: `draggable-product${product.id}`,
    parentId: product.idParentFolder,
  };

  const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
    userSelect: "none",
    paddingTop: "10px",
    borderRadius: "10px",
    boxShadow: isDragging ? "10px 0px 20px rgba(0,0,0,0.2)" : "none",
    backgroundColor: isDragging ? "white" : "transparent",
    ...draggableStyle,
  });
  const pathnames = window.location.pathname.split("/");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const [selectedClient, setSelectedClient] = useState("");
  const [selectedClientClients, setSelectedClientClient] = useState("");

  // This function is triggered when the selection changes
  const handleSelectChange = (value: any, event: any) => {
    setSelectedValue(value);
  };

  const handleSelectChangeClient = (value: any, event: any) => {
    setSelectedClientClient(value);
  };
  const showModal = (e: any) => {
    //@ts-ignore
    e.preventDefault(); // Prevent default action (for anchor tags, if you're using one for the icon)
    e.stopPropagation();
    setIsModalOpen(true);
  };

  const handleOk = (e: any) => {
    e.preventDefault();
    setIsModalOpen(false);
  };

  const handleCancel = (e: any) => {
    e.preventDefault();
    setIsModalOpen(false);
  };

  const confirm = async (e: any, product_id: any) => {
    e.preventDefault();

    let token = localStorage.getItem("token");
    let company_id = localStorage.getItem("company_id");
    if (user.type == "admin" || user.type == "commercial") {
      console.log(user);
      if (selectedValue) {
        setStarCheck(true);
        await axiosInstance({
          method: "post",
          url: `/admin/attachforyou/${selectedValue}`,
          data: {
            product_id: product_id,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }).then(function (response) {
          if (response.status === 200) {
            MyNotif(`Product attached successfully  `, "success");
            setFY(product_id);
            setStarCheck(false);
            //   refetch();
          }
        });
        return "ok";
      } else {
        MyNotif(`Please select the company`, "error");
      }
    } else {
      if (selectedClientClients) {
        setStarCheck(true);
        await axiosInstance({
          method: "post",
          url: `/clients/attachforyou`,
          data: {
            product_id: product_id,
            clients: selectedClientClients,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }).then(function (response) {
          if (response.status === 200) {
            MyNotif(`Product attached successfully  `, "success");
            setFY(product_id);
            setStarCheck(false);
            //   refetch();
          }
        });
        return "ok";
      } else {
        MyNotif(`Please select the client`, "error");
      }
    }
  };

  const confirmdeattch = async (e: any, product_id: any) => {
    e.preventDefault();

    let token = localStorage.getItem("token");
    let company_id = localStorage.getItem("company_id");
    if (company_id) {
      setStarCheck(true);
      await axiosInstance({
        method: "post",
        url: `/admin/detachforyou/${company_id}`,
        data: {
          product_id: product_id,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then(function (response) {
        if (response.status === 200) {
          setStarCheck(false);
          MyNotif(`Product detached successfully  `, "success");
          setFY(0);
          //   setStarCheck(0);
          //   refetch();
        }
      });
      return "ok";
    } else {
      MyNotif(`Please select the company`, "error");
    }
  };

  return (
    <Draggable
      key={product.id}
      draggableId={JSON.stringify(draggableProductData)}
      index={index}
      disableInteractiveElementBlocking
      isDragDisabled={true}
    >
      {(provided, snapshot) => (
        <div
          // className="one_product_link"
          className="grid-item-product"
          key={index}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={getItemStyle(
            snapshot.isDragging,
            provided.draggableProps.style
          )}
        >
          <Link
            to={`/product/details/${params.fabric}/${params.gendre}/${params.category}/${product.name}/${product.id}`}
            style={{ textDecoration: "none" }}
          >
            <Card
              // style={{
              //   width: width < 768 ? "100%" : "18rem",
              //   padding: 15,
              // }}
              bordered={false}
              bodyStyle={{ padding: "0px" }}
              cover={
                <div className={`product_item_new ${colorStroke()} mb-2`}>
                  <LazyImg
                    alt={item.name}
                    style={{
                      position: "absolute",
                      zIndex: 2,
                      opacity: 0.2,
                      objectFit: "cover",
                      width: "100%",
                      height: width > "450px" ? "360px" : "300px",
                    }}
                    src={watermak}
                    thumb={watermak}
                  />
                  <LazyImg
                    className="img-front"
                    alt={item.name}
                    style={{
                      objectFit: "contain",
                      width: "100%",
                      height: width > "450px" ? "360px" : "300px",
                    }}
                    src={
                      item.main
                        ? process.env.REACT_APP_STORAGE_URL + item.main
                        : placeholder_jeans
                    }
                    thumb={
                      item.main
                        ? process.env.REACT_APP_STORAGE_URL + item.main
                        : placeholder_jeans
                    }
                  />
                  <LazyImg
                    className="img-back"
                    alt={item.name}
                    src={
                      item.onHover
                        ? process.env.REACT_APP_STORAGE_URL + item.onHover
                        : placeholder_jeans
                    }
                    thumb={
                      item.onHover
                        ? process.env.REACT_APP_STORAGE_URL + item.onHover
                        : placeholder_jeans
                    }
                    style={{
                      objectFit: "contain",
                      height: width > "450px" ? "360px" : "300px",
                    }}
                  />
                  {product.labels.find((el: any) => el.name === "NEW") &&
                    // !history.location.pathname.includes("new") &&
                    moment(product.label_new_remove_date).isAfter(
                      moment(new Date())
                    ) && (
                      <div className="new_product_label">
                        <label className={"product_name_label"}>NEW </label>
                      </div>
                    )}
                  {product.labels.find((el: any) => el.name === "NEW") &&
                    history.location.pathname.includes("new") && (
                      <div className="new_product_label">
                        <label className={"product_name_label"}>NEW</label>
                      </div>
                    )}
                  {product.is_3d === 1 && (
                    <div
                      style={{
                        width: "40px",
                        height: "40px",
                        background: "rgba(255, 255, 255, 0.2)",
                        position: "absolute",
                        top: "5px",
                        left: "5px",
                      }}
                      className="d-flex align-items-center justify-content-center p-0"
                    >
                      <img
                        src={icon3D}
                        alt="3D product icon"
                        style={{ maxWidth: "80%", height: "auto" }}
                      />
                    </div>
                  )}

                  {productPage &&
                    user &&
                    isAdmin &&
                    localStorage.getItem("company_name") != null && (
                      <>
                        <>
                          <FontAwesomeIcon
                            style={{
                              position: "absolute",
                              bottom: "5px",
                              right: "5px",
                            }}
                            icon={starCheck ? faSpinner : faStar}
                            className={
                              starCheck
                                ? "favorite_icon favorite_loading"
                                : "favorite_icon"
                            }
                            spin={starCheck}
                            onClick={showModal}
                          />
                          <Modal
                            maskClosable={true}
                            title="Select Company to attach product"
                            visible={isModalOpen}
                            onOk={(e) => confirm(e, product.id)}
                            onCancel={handleCancel}
                          >
                            <div onClick={(e) => e.stopPropagation()}>
                              <Select
                                style={{
                                  width: "100%",
                                }}
                                onClick={(e) => e.stopPropagation()}
                                onChange={handleSelectChange}
                                value={selectedValue}
                                dropdownStyle={{
                                  borderRadius: 10,
                                  fontWeight: 600,
                                  fontSize: 20,
                                }}
                                suffixIcon={
                                  <FontAwesomeIcon icon={faCaretDown} />
                                }
                              >
                                <Select.Option value="">
                                  Select the company
                                </Select.Option>
                                {company?.data.map((el: any, index: any) => (
                                  <>
                                    <Select.Option key={index} value={el.id}>
                                      {el.name}
                                    </Select.Option>
                                  </>
                                ))}
                              </Select>
                            </div>
                          </Modal>{" "}
                          {/*<Popconfirm
                              placement="top"
                              title={`Do you want to proceed with attaching the product to ${
                                localStorage.getItem("company_name") != null
                                  ? localStorage.getItem("company_name")
                                  : ""
                              }`}
                              onConfirm={(e) => confirm(e, product.id)}
                              okText="Yes"
                              cancelText="No"
                              onCancel={(e) => {
                                handleCancel(e);
                              }}
                            >

                              <FontAwesomeIcon
                                style={{
                                  position: "absolute",
                                  bottom: "5px",
                                  right: "5px",
                                }}
                                icon={starCheck ? faSpinner : faStar}
                                className={
                                  starCheck
                                    ? "favorite_icon favorite_loading"
                                    : "favorite_icon"
                                }
                                spin={starCheck}
                              />
                            </Popconfirm>*/}
                        </>
                      </>
                    )}
                  {productPage && user && !isAdmin && (
                    <div
                    // className="product_card_icons"
                    // style={{
                    //   position: "absolute",
                    //   top: 0,
                    //   width: "100%",
                    //   flexDirection: "row",
                    // }}
                    >
                      {!user.is_client_clients && (
                        <FontAwesomeIcon
                          style={{
                            position: "absolute",
                            top: "5px",
                            left: "5px",
                          }}
                          icon={starCheck ? faSpinner : faStar}
                          className={
                            starCheck
                              ? "favorite_icon favorite_loading"
                              : "favorite_icon"
                          }
                          spin={starCheck}
                          onClick={showModal}
                        />
                      )}
                      <Modal
                        maskClosable={true}
                        title="Select client to attach product"
                        visible={isModalOpen}
                        onOk={(e) => confirm(e, product.id)}
                        onCancel={handleCancel}
                      >
                        <div onClick={(e) => e.stopPropagation()}>
                          <Select
                            mode="multiple"
                            style={{
                              width: "100%",
                            }}
                            onClick={(e) => e.stopPropagation()}
                            onChange={handleSelectChangeClient}
                            dropdownStyle={{
                              borderRadius: 10,
                              fontWeight: 600,
                              fontSize: 20,
                            }}
                            suffixIcon={<FontAwesomeIcon icon={faCaretDown} />}
                          >
                            {clients?.map((el: any, index: any) => (
                              <>
                                <Select.Option key={index} value={el.id}>
                                  {el.name}
                                </Select.Option>
                              </>
                            ))}
                          </Select>
                        </div>
                      </Modal>
                      <Link
                        to={`/customization/new/${product.id}`}
                        className="customize_icon"
                        style={{
                          position: "absolute",
                          bottom: "5px",
                          right: "2px",
                        }}
                      >
                        <img src={customizeIcon} alt="customize product icon" />
                      </Link>

                      {selection.selectionProducts &&
                      selection.selectionProducts.some(
                        (el: any) => el.id === product.id
                      ) ? (
                        <FontAwesomeIcon
                          style={{
                            position: "absolute",
                            top: "5px",
                            right: "5px",
                          }}
                          icon={
                            selection.productToAddId === product.id &&
                            selection.isLoading
                              ? faSpinner
                              : faHeart
                          }
                          className={
                            selection.productToAddId === product.id &&
                            selection.isLoading
                              ? "favorite_icon favorite_loading"
                              : "favorite_icon favorite_icon_red"
                          }
                          spin={
                            selection.productToAddId === product.id &&
                            selection.isLoading
                          }
                          onClick={(event: any) => {
                            event.preventDefault();
                            event.stopPropagation();
                            dispatch(removeProductFromSelection(product.id));
                          }}
                        />
                      ) : (
                        <FontAwesomeIcon
                          style={{
                            position: "absolute",
                            top: "5px",
                            right: "5px",
                          }}
                          icon={
                            selection.productToAddId === product.id &&
                            selection.isLoading
                              ? faSpinner
                              : farHeart
                          }
                          spin={
                            selection.productToAddId === product.id &&
                            selection.isLoading
                          }
                          className={
                            selection.productToAddId === product.id &&
                            selection.isLoading
                              ? "favorite_icon favorite_loading"
                              : "favorite_icon"
                          }
                          onClick={(event: any) => {
                            event.preventDefault();
                            event.stopPropagation();
                            dispatch(addProductToSelection(product.id));
                          }}
                        />
                      )}
                    </div>
                  )}
                </div>
              }
            >
              <Meta
                description={
                  productPage &&
                  product && (
                    <div className="d-flex mt-2">
                      <div className="labels_list">
                        {productPage &&
                          product.labels &&
                          product.labels.map((el: any, i: number) =>
                            el.name === "SUSTAINABLE" ? (
                              <div className="sustain_product_label" key={i}>
                                <img src={sustainableLogo} alt="sustainable" />
                                <label className={"product_name_label"}>
                                  {el.name}
                                </label>
                              </div>
                            ) : !product.labels.find(
                                (el: any) => el.name === "SUSTAINABLE"
                              ) &&
                              !product.labels.find(
                                (el: any) => el.name === "NEW"
                              ) &&
                              product.favorite_by ? (
                              <div className="favorite_product_label" key={i}>
                                <p className="favorite_by">
                                  {product.favorite_by.name}' favorite
                                </p>
                              </div>
                            ) : null
                          )}
                        <div>
                          <h4
                            /*  className={"product_name_label"}*/
                            className={" mb-0 product_name_label"}
                          >
                            {product.code}{" "}
                            {pathnames[1] == "new" ||
                            pathnames[1] == "collection" ? (
                              <>
                                {product.gender_id == 2 ? (
                                  <>-Women</>
                                ) : product.gender_id == 1 ? (
                                  <>-Men</>
                                ) : product.gender_id == 3 ? (
                                  <>-Unisex</>
                                ) : product.gender_id == 4 ? (
                                  <>-Kids</>
                                ) : product.gender_id == 5 ? (
                                  <>-Boys</>
                                ) : product.gender_id == 6 ? (
                                  <>-Girls</>
                                ) : (
                                  ""
                                )}
                              </>
                            ) : (
                              ""
                            )}
                          </h4>
                          <h4
                            /*        className={"product_name_label"}*/
                            className={" product_name_label"}
                          >
                            {product.name}
                          </h4>
                        </div>
                      </div>
                      <div className="d-flex">
                        {productPage &&
                          product &&
                          product.favorite_by &&
                          product.favorite_by.avatar && (
                            <img
                              src={
                                process.env.REACT_APP_STORAGE_URL +
                                product.favorite_by.avatar
                              }
                              className="favorite_by_img"
                            />
                          )}
                      </div>
                    </div>
                  )
                }
              />
            </Card>
          </Link>
        </div>
      )}
    </Draggable>
  );
};

export default ExampleProductCard;